import { useState } from 'react';
import fm from 'format-message';
import PropTypes from 'prop-types';
import Image from '@activebrands/core-web/components/Image';
import ScrollableBox from '@activebrands/core-web/components/ScrollableBox';
import media from '@activebrands/core-web/config/media';
import { styled } from '@activebrands/core-web/libs/styletron';
import ThemeButton from 'components/buttons/ThemeButton';
import OverlayHeader from 'components/overlays/OverlayHeader';

const Wrapper = styled('div', {
    width: 'var(--width-overlays)',
    height: `var(--height-overlay)`,
    margin: '4px',
});

const InnerWraper = styled('div', {
    alignItems: 'space-between',
    background: 'var(--color-bg)',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '0 12px 12px 12px',
    position: 'relative',
    width: '100%',

    [media.min['mobile.lg']]: {
        marginRight: '4px',
    },
});

const OverlayFooter = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '8px',
    alignItems: 'center',
});

const SizeGuideOverlay = ({
    defaultActiveGuide = '',
    howToMeasureSrc = '',
    sizeguideLabel = '',
    sizeguideSrc = '',
}) => {
    const [activeGuide, setActiveGuide] = useState(
        defaultActiveGuide || (sizeguideSrc ? 'size-guide' : 'how-to-measure-guide')
    );

    const buttonStyles = { width: 'calc(50% - 4px)' };

    return (
        <Wrapper>
            <InnerWraper>
                <OverlayHeader headingText={sizeguideLabel} overlayId="sizeguide" />
                <ScrollableBox $style={{ flexGrow: 1 }}>
                    <div>
                        <Image
                            $style={{ margin: '0 auto', width: ['100%', '360px', 'calc(420px - 24px)'] }} // 24px padding
                            src={{
                                url: activeGuide === 'size-guide' ? sizeguideSrc : howToMeasureSrc,
                                width: [420, 840],
                            }}
                            sizes="420px"
                        />
                    </div>
                </ScrollableBox>
                {sizeguideSrc && howToMeasureSrc && (
                    <OverlayFooter>
                        {sizeguideSrc && (
                            <ThemeButton
                                $style={buttonStyles}
                                active={activeGuide === 'size-guide'}
                                onClick={() => setActiveGuide('size-guide')}
                            >
                                {fm('Size guide')}
                            </ThemeButton>
                        )}
                        {howToMeasureSrc && (
                            <ThemeButton
                                $style={buttonStyles}
                                active={activeGuide === 'how-to-measure-guide'}
                                onClick={() => setActiveGuide('how-to-measure-guide')}
                            >
                                {fm('How to measure')}
                            </ThemeButton>
                        )}
                    </OverlayFooter>
                )}
            </InnerWraper>
        </Wrapper>
    );
};

SizeGuideOverlay.propTypes = {
    defaultActiveGuide: PropTypes.string,
    howToMeasureSrc: PropTypes.string,
    sizeguideLabel: PropTypes.string.isRequired,
    sizeguideSrc: PropTypes.string,
};

export default SizeGuideOverlay;
